.App {
  text-align: center;
  background-color: #faf7f2;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(135deg, #ff6b6b 0%, #ffc3a0 100%);
  padding: 4rem 2rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

h1 {
  font-size: 4rem;
  margin: 0;
  font-weight: 700;
  font-family: 'Playfair Display', serif;
}

.subtitle {
  font-size: 1.4rem;
  margin-top: 1rem;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.App-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

section {
  margin: 4rem 0;
  padding: 2.5rem;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

h2 {
  color: #ff6b6b;
  margin-bottom: 2rem;
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
}

.passions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.passion-item {
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  transition: transform 0.2s;
}

.passion-item:hover {
  transform: translateY(-5px);
}

.passion-item h3 {
  color: #3498db;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.social-links a {
  color: #3498db;
  text-decoration: none;
  font-weight: 600;
  padding: 0.5rem 1rem;
  border: 2px solid #3498db;
  border-radius: 25px;
  transition: all 0.3s;
}

.social-links a:hover {
  background-color: #3498db;
  color: white;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
  
  .App-main {
    padding: 1rem;
  }
  
  .passions-grid {
    grid-template-columns: 1fr;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
